export const colors = {
  background: {
    nav: "#000000",
    section: {
      dark: "#000C14",
      light: "#ECFBFD",
    },
    card: {
      dark: "#003152",
      light: "#90E8F3",
    },
  },
  text: {
    nav: {
      dark: "#ECFBFD",
      light: "#000C14",
    },
    section: {
      dark: "#ECFBFD",
      light: "#000C14",
    },
  },
  borders: {
    dark: "#C7BEBD",
    light: "#023B28",
  },
};
