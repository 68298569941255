import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import Slider from "react-input-slider";
import {
  colors,
  targetNetwork,
  useTitle,
  ExternalLink,
  EthereumContext,
  SaleStatus,
  icons,
  Divider,
  constants,
  ParticlesBackground,
  Button,
  Background,
  Loading,
  Status,
  log,
  processError,
  SaleState,
} from "../index";
import openseaLogo from "../../images/opensea.svg";
import video from "../../images/dcave.mp4";
import watch from "../../images/watch.mp4";

const MintPageSection = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  padding-bottom: 45px;
  width: 100%;
  transition: 0.4s ease all;
  justify-content: center;
  background-color: black;
  color: ${({ theme }: { theme: string }) =>
    theme === "dark" ? colors.text.section.dark : colors.text.section.light};
`;

const MintFrame = styled.div`
  // position: relative;
  display: flex;
  flex-direction: column;
  width: 500px;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
  justify-content: center;
  align-items: center;
  z-index: 1;
`;
const MintStatus = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

const MintTitleFrame = styled.div``;
const MintTitle = styled.h1`
  font-family: "${constants.fonts.title}";
  text-shadow: 3px 1px 3px #000000, 3px 1px 12px #000000;
  letter-spacing: 2px;
  text-align: center;
  color: white;
`;

const MintImageFrame = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const MintImage = styled.img`
  border: 2px solid white;
  background-color: #79b4e0;
  margin: 0px 20px 10px 20px;
  width: 85%;
  height: auto;
  border-radius: 20px;
  box-shadow: #000000 2px 2px 0px, #000000 2px 2px 0px,
    2px 1px 3px rgba(0, 0, 0, 0);
`;

const MintQuantityFrame = styled.div`
  display: flex;
  justify-content: center;

  align-items: center;
  margin: 10px 0px 20px 0px;
`;
const MintQuantitySlider = styled(Slider)`
  div:first-child {
    box-shadow: #000000 2px 2px 0px, #000000 2px 2px 0px,
      2px 1px 3px rgba(0, 0, 0, 0);
  }
`;
const MintQuantity = styled.div`
  padding: 0px 15px;
  display: flex;
  justify-content: center;
  min-width: 50px;
  background-color: #83d544;
  color: black;
  font-family: arial;
  font-weight: bold;
  border-radius: 12px;
  margin-left: 20px;
  // box-shadow: #000000 2px 2px 0px, #000000 2px 2px 0px,
  //   2px 1px 3px rgba(0, 0, 0, 0);
`;

const MintButtonFrame = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const AddOnButtonFrame = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const MintSupplyFrame = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OpenseaLogo = styled.img`
  height: auto;
  width: 100px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const LogoFrame = styled.div`
  margin-top: 100px;
  margin-bottom: 65px;
  max-width: 1600px;
  @media screen and (max-width: 1050px) {
    margin-top: 50px;
    margin-bottom: 35px;
  }
`;

const LogoImg = styled.img`
  width: 100%;
  @media screen and (max-width: 1050px) {
    width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`;

const VideoPlayer = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  // z-index: -100;
`;
const Video = () => {
  return (
    <VideoPlayer autoPlay loop muted>
      <source src={video} />
    </VideoPlayer>
  );
};
const WatchFrame = styled.div`
  width: 300px;
`;

const WatchVideo = styled.video`
  /* position: absolute; */
  width: 100%;
  height: 100%;
  border-radius: 20px;
  border: 2px solid #9fd452;
  object-fit: cover;
  // z-index: -100;
`;

const WatchVideoPlayer = () => {
  return (
    <WatchVideo autoPlay loop muted>
      <source src={watch} />
    </WatchVideo>
  );
};

export const Mint: React.FunctionComponent = () => {
  useTitle({ page: `Mint ${constants.token.symbol}` });
  const [mintQuantity, setMintQuantity] = useState({ x: 1 });
  const { contract, contractNode, contractState, account } =
    useContext(EthereumContext);

  const onWithdraw = async () => {
    contract!
      .withdraw()
      .then((data: any) => {
        console.log({ data });
        toast.dark(
          <ExternalLink
            href={`${targetNetwork.blockExplorer}tx/${data.hash}`}
            target="_BLANK"
            rel="noreferrer"
          >
            {`Withdrew funds! ✅ Click to view transaction on Etherscan 🔗`}
          </ExternalLink>
        );
      })
      .catch((error: any) => processError(error));
  };

  const onMint = async () => {
    const saleState: number = await contractNode!.saleState();
    if (saleState === SaleState.Off)
      return toast.error(`The sale has not started yet.`);

    const sale = await contractNode!.saleData(saleState);
    const maxSupply: ethers.BigNumber = sale.maxTokensInSale;
    const totalSupply: ethers.BigNumber = await contractNode!.totalSupply();
    const remainingSupply: ethers.BigNumber = maxSupply.sub(totalSupply);
    const mintQty: ethers.BigNumber = ethers.BigNumber.from(mintQuantity.x);
    const sufficientQtyRemain: boolean = remainingSupply.gte(mintQty);
    // console.log(`Attempting to mint ${mintQty.toNumber()} token(s)..`);
    // console.log(`Remaining supply: ${remainingSupply.toNumber()}`);
    // console.log(`sufficientQtyRemain: ${sufficientQtyRemain}!`);
    if (!sufficientQtyRemain)
      return toast.error(
        `Error! Only ${remainingSupply.toNumber()} ${
          constants.token.symbol
        } remain! ⚠`
      );
    const price: ethers.BigNumber = sale.price;
    const symbol: string = await contractNode!.symbol();
    const totalCost: ethers.BigNumber = price.mul(mintQty);

    let estimation: ethers.BigNumber | undefined = undefined;

    try {
      estimation = await contract!.estimateGas.mintPublic(mintQty, {
        value: totalCost,
      });
    } catch (err: any) {
      processError(err);
    }

    if (!estimation) return;

    const prom = contract!.mintPublic(mintQty, {
      value: totalCost,
      gasLimit: Math.floor(estimation.toNumber() * 1.2),
    });

    prom
      .then((data: any) => {
        log(data);
        // triggerSuccess();
        toast.dark(
          <ExternalLink
            href={`${targetNetwork.blockExplorer}tx/${data.hash}`}
            target="_BLANK"
            rel="noreferrer"
          >
            Minted {mintQty.toNumber()} {symbol}
            {"! "}
            {`Click to view transaction on Etherscan 🔗`}
          </ExternalLink>,
          { autoClose: 15000 }
        );
      })
      .catch((error: any) => processError(error));
  };

  const FaTwitterSquare = icons.FaTwitterSquare;
  return (
    <React.Fragment>
      {/* <ParticlesBackground /> */}
      <MintPageSection className="noselect">
        {/* <Background background={background} /> */}
        <Video />
        <MintFrame>
          <MintTitleFrame>
            <MintStatus>
              <SaleStatus />
            </MintStatus>
          </MintTitleFrame>
          <WatchFrame>
            <WatchVideoPlayer />
          </WatchFrame>
          {contractState &&
          !contractState.saleIsCompleted &&
          contractState.price &&
          contractState.saleState != null &&
          contractState.maxMintPerTransaction &&
          contractState.saleState > SaleState.Off ? (
            <>
              <h1
                style={{
                  color: "#83d544",
                  margin: 0,
                  padding: 0,
                  fontFamily: "Monument",
                  marginTop: 10,
                  fontSize: 18,
                }}
              >
                Quantity to Mint
              </h1>

              {contractState.maxTokensInSale! - contractState.counter! > 0 ? (
                <>
                  {/* Slider to select quantity to mint */}
                  {contractState.maxTokensInSale! - contractState.counter! >
                    1 &&
                    // contractState.maxMintPerAddress! - contractState.mintCount! > 1
                    contractState.maxMintPerTransaction! && (
                      <MintQuantityFrame>
                        <MintQuantitySlider
                          axis="x"
                          xmin={1}
                          xmax={Math.min(
                            contractState.maxMintPerTransaction!,
                            // contractState.maxMintPerAddress! -
                            //   contractState.mintCount!,
                            contractState.maxTokensInSale! -
                              contractState.counter!
                          )}
                          xstep={1}
                          x={mintQuantity.x}
                          onChange={({ x }: { x: number }) =>
                            setMintQuantity((state: any) => ({ ...state, x }))
                          }
                          styles={{
                            track: {
                              backgroundColor: "#83D544",
                            },
                            active: {
                              // border: "2px solid white",
                              background: "#b3e18f",
                            },
                            thumb: {
                              width: 30,
                              height: 30,
                              backgroundColor: "black",
                              border: "2px solid #83D544",
                            },
                            disabled: {
                              opacity: 0.5,
                            },
                          }}
                        />
                        <MintQuantity>{mintQuantity.x}</MintQuantity>
                      </MintQuantityFrame>
                    )}

                  {/* Submit mint button */}
                  <MintButtonFrame>
                    <Button
                      whileTap={{ scale: 0.9 }}
                      whileHover={{ scale: 1.1 }}
                      onClick={onMint}
                    >
                      MINT (
                      {ethers.utils.formatEther(
                        contractState.price.mul(mintQuantity.x)
                      )}
                      Ξ)
                    </Button>
                  </MintButtonFrame>
                </>
              ) : (
                <Status>You Minted the Maximum Tokens in this Sale</Status>
              )}
            </>
          ) : (
            <></>
          )}

          {/* Display remaining supply  */}
          <MintSupplyFrame>
            <Status>
              {contractState && contractState.supplyRemaining ? (
                `${contractState.supplyRemaining}/${constants.token.maxSupply} Remaining`
              ) : (
                <Loading text="supply" />
              )}
            </Status>
          </MintSupplyFrame>
          {/* Withdrawal button for dev */}

          {contractState &&
          contractState.owner &&
          account &&
          contractState.owner === account ? (
            <React.Fragment>
              <MintTitleFrame>
                <Divider title="Owner Only" color="white" shadow={true} />
              </MintTitleFrame>
              <MintButtonFrame>
                <Button
                  whileTap={{ scale: 0.9 }}
                  whileHover={{ scale: 1.1 }}
                  onClick={onWithdraw}
                >
                  WITHDRAW
                </Button>
              </MintButtonFrame>
            </React.Fragment>
          ) : (
            <></>
          )}
        </MintFrame>
      </MintPageSection>
    </React.Fragment>
  );
};
