import { HiOutlineViewGridAdd } from "react-icons/hi";
import { MdLibraryBooks } from "react-icons/md";
import { NETWORKS } from "./networks";

//set the target network
export const targetNetwork = NETWORKS.rinkeby;

export const constants = {
  token: {
    symbol: "COMPUTRON",
    maxSupply: 1000,
  },
  logo: `D-Cave x Bulova`,
  footer: `Join`,
  projectName: `D-Cave x Bulova NFT`,
  projectHomepage: `/`,
  baseCurrency: "ETH",
  supportedChainIds: [targetNetwork.chainId],
  socials: {
    atlasDiscord: "84GEXy3UxM",
    discord: "Rd9BRsrGKg",
    twitter: "dcaveofficial",
    instagram: "dcaveofficial",
  },
  shareTweet: {
    text: "I just minted a @unethicalcupid! Join the army now at",
    url: "https://mint.unethicalcupid.io",
    hashtags: [
      "CUPID",
      "UnETHicalCupid",
      "UnETHicalCupid",
      "NFTCollector",
      "NFT",
    ],
  },
  fonts: {
    title: "DIN-Black",
    body: "MuseoSansRounded-100",
  },
  cookiePrefix: "atlas",
  navMenu: [
    {
      path: "/",
      text: "Mint",
      icon: HiOutlineViewGridAdd,
      include: ["sidebar", "navbar"],
    },
    {
      path: "/provenance",
      text: "Provenance",
      icon: MdLibraryBooks,
      include: ["sidebar", "navbar"],
    },
  ],
  pollerInterval: 25000,
};
