import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { EthereumContext, icons } from "../index";
import { targetNetwork } from "../constants";
import { Loading } from "./Loading";

const StatusContainer = styled.div`
  font-size: 1rem;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const TextWrap = styled.span`
  transition: 0.2s ease-in-out all;
  display: inline-block;
  color: #83d544;
  background-color: black;
  padding: 5px 8px;
  font-family: Monument;
  font-weight: bold;
  border-radius: 8px;
  font-size: 18px;
  max-width: 375px;
  // box-shadow: #000000 2px 2px 0px, #000000 2px 2px 0px,
  //   2px 1px 3px rgba(0, 0, 0, 0);
`;

export function getSaleStatus(status: number, chainId: number): any {
  const FaSpinner = icons.FaSpinner;
  const inTestnet = chainId !== 1;
  let element: any;
  switch (status) {
    default:
    case -1:
      //loading
      element = <Loading text="status" />;
      break;
    // case 1:
    //   element = `Presale is live! ✅ ${inTestnet ? " (TESTNET)" : ""}`;
    //   break;
    case 1:
      element = `Public sale is live!  ${inTestnet ? " (TESTNET)" : ""}`;
      break;
    case 2:
      element = `Public sale is live!  ${inTestnet ? " (TESTNET)" : ""}`;
      break;
    case 0:
      element = (
        <>
          Waiting for sale to go live {inTestnet ? " (TESTNET)" : ""}{" "}
          &nbsp;&nbsp;
          <FaSpinner className="icon-spin" style={{ marginRight: 5 }} />
        </>
      );
      break;
  }
  return element;
}

export const SaleStatus: React.FunctionComponent = () => {
  const { contractState } = useContext(EthereumContext);
  const [element, setElement] = useState<any>(
    getSaleStatus(-1, targetNetwork.chainId)
  );
  useEffect(() => {
    if (!contractState) return;
    if (contractState.saleIsCompleted)
      return setElement(getSaleStatus(3, targetNetwork.chainId));
    const { saleState } = contractState!;
    if (saleState !== undefined && saleState !== null) {
      setElement(getSaleStatus(saleState, targetNetwork.chainId));
    }
  }, [contractState?.saleState]);

  return <Status>{element && element}</Status>;
};

export const Status: React.FC = (props) => {
  return (
    <StatusContainer>
      <TextWrap>{props.children}</TextWrap>
    </StatusContainer>
  );
};
