import styled from "styled-components";
import { motion } from "framer-motion";
import { colors } from "../index";
import quickNode from "../../images/quicknode.png";
import atlascorp from "../../images/atlascorp.png";
import { constants, icons } from "../index";
import tweeter from "../../images/Twitter.png";
import insta from "../../images/Instgram.png";
import discord from "../../images/Discort.png";
const FooterNav = styled.nav`
  font-family: arial;
  font-weight: 400;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease all;
  min-height: 70px;
  z-index: 10;
  background-color: black;
`;

const Link = styled(motion.a)`
  color: white;
  font-size: 25px;
  margin: 0px 3px;
  &:hover {
    color: #9fd452;
  }
`;

const QuickNodeImg = styled.img`
  filter: invert(100%);
  height: auto;
  width: 125px;
`;
const AtlasCorpImg = styled.img`
  filter: invert(100%);
  height: auto;
  width: 175px;
`;

const FooterCol = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
`;

const FlexCenter = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const Footer: React.FunctionComponent = () => {
  const TwitterIcon = icons.FaTwitterSquare;
  const DiscordIcon = icons.FaDiscord;
  const InstagramIcon = icons.FaInstagramSquare;

  return (
    <FooterNav className="noselect">
      <div className="container" style={{ margin: 20 }}>
        <div className="row text-center w-100 py-xs-3">
          <FooterCol className="col-12 col-lg-4 order-lg-first">
            <FlexCenter>
              <span>
                POWERED BY:{" "}
                <Link
                  href={`https://quicknode.com`}
                  whileTap={{ scale: 0.8 }}
                  whileHover={{ scale: 1.15 }}
                  target="_BLANK"
                  rel="noreferrer"
                >
                  <QuickNodeImg src={quickNode} alt="QuickNode" />
                </Link>
              </span>
            </FlexCenter>
          </FooterCol>
          <FooterCol className="col-12 col-lg-4 order-first my-sm-2">
            <FlexCenter>
              <div>
                <Link
                  href={`https://twitter.com/${constants.socials.twitter}`}
                  whileTap={{ scale: 0.8 }}
                  whileHover={{ scale: 1.15 }}
                  target="_BLANK"
                  rel="noreferrer"
                >
                  {/* <TwitterIcon /> */}
                  <TwitterIcon />
                </Link>

                <Link
                  href={`https://instagram.com/${constants.socials.instagram}`}
                  whileTap={{ scale: 0.8 }}
                  whileHover={{ scale: 1.15 }}
                  target="_BLANK"
                  rel="noreferrer"
                >
                  {/* <InstagramIcon /> */}
                  <InstagramIcon />
                </Link>

                <Link
                  href={`https://discord.gg/${constants.socials.discord}`}
                  whileTap={{ scale: 0.8 }}
                  whileHover={{ scale: 1.15 }}
                  target="_BLANK"
                  rel="noreferrer"
                >
                  {/* <DiscordIcon /> */}
                  <DiscordIcon />
                </Link>
              </div>
            </FlexCenter>
          </FooterCol>
          <FooterCol className="order-last col-lg-4 col-sm-12 col-12">
            <FlexCenter>
              <span>
                Developed in 2022 by
                <Link
                  href={`https://discord.gg/${constants.socials.atlasDiscord}`}
                  whileTap={{ scale: 0.8 }}
                  whileHover={{ scale: 1.15 }}
                  target="_BLANK"
                  rel="noreferrer"
                  style={{ paddingLeft: "3px" }}
                >
                  <AtlasCorpImg src={atlascorp} alt="Atlas Corporation" />
                </Link>
              </span>
            </FlexCenter>
          </FooterCol>
        </div>
      </div>
    </FooterNav>
  );
};
