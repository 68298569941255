import { useEffect, useState, createContext, useMemo } from "react";
import { BigNumber, ethers, providers, Contract } from "ethers";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from "@web3-react/injected-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorWalletConnect } from "@web3-react/walletconnect-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorFrame } from "@web3-react/frame-connector";
import { InjectedConnector } from "@web3-react/injected-connector";
// import { NetworkConnector } from '@web3-react/network-connector';
import { NetworkConnector } from "../components/NetworkConnector"; //haxed
import { toast } from "react-toastify";
import { contractInformation, constants, targetNetwork } from "../constants";
import { useOnRepetition } from "eth-hooks";
import { log } from "..";
import { serializeError, getMessageFromCode } from "eth-rpc-errors";
import whitelistAddresses from "../constants/whitelist.json";
import { MerkleTree } from "merkletreejs";
import keccak256 from "keccak256";
//dedicated node
export const network = new NetworkConnector({
  urls: { [targetNetwork.chainId]: targetNetwork.rpcUrl },
});

//metamask
export const injected = new InjectedConnector({
  supportedChainIds: constants.supportedChainIds,
});

export const useEagerConnect = (): boolean => {
  const { activate, active } = useWeb3React("metamask");
  const [tried, setTried] = useState(false);
  useEffect(() => {
    injected.isAuthorized().then((isAuthorized: boolean) => {
      if (isAuthorized) {
        activate(injected, undefined, true).catch(() => {
          setTried(true);
        });
      } else {
        setTried(true);
      }
    });
    // eslint-disable-next-line
  }, []); // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && active) {
      setTried(true);
    }
  }, [tried, active]);

  return tried;
};

export function useInactiveListener(suppress = false): void {
  const { active, error, activate } = useWeb3React("metamask");

  useEffect(() => {
    const { ethereum } = window as any;
    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleConnect = () => {
        log("Handling 'connect' event");
        activate(injected);
      };
      const handleChainChanged = (chainId: string | number) => {
        log("Handling 'chainChanged' event with payload", chainId);
        activate(injected);
      };
      const handleAccountsChanged = (accounts: string[]) => {
        log("Handling 'accountsChanged' event with payload", accounts);
        if (accounts.length > 0) {
          activate(injected);
        }
      };
      ethereum.on("connect", handleConnect);
      ethereum.on("chainChanged", handleChainChanged);
      ethereum.on("accountsChanged", handleAccountsChanged);
      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener("connect", handleConnect);
          ethereum.removeListener("chainChanged", handleChainChanged);
          ethereum.removeListener("accountsChanged", handleAccountsChanged);
        }
      };
    }
  }, [active, error, suppress, activate]);
}

export interface IEthereumContextValues {
  connect: () => void;
  logout: () => void;
  library: providers.Web3Provider;
  account: string | null | undefined;
  isConnectedToMetamask: boolean | undefined;
  errorMsg: string | null | undefined;
  nodeProvider: providers.Web3Provider;
  contract: ethers.Contract;
  contractNode: ethers.Contract;
  contractState: IContractState;
  contracts: Record<string, Contract>;
  merkleTree: MerkleTree | null;
  hexProof: string[] | null;
  isOnWhiteList: boolean | null;
}

export interface IContractState {
  owner: string | null;
  saleState: SaleState | null;
  balanceOf: number;
  maxSupply: BigNumber | null;
  maxMintPerTransaction: number | null;
  baseURI: string | null;
  tokensInWallet: BigNumber[];
  totalSupply: number | null;
  saleIsCompleted: boolean;
  supplyRemaining: number | null;
  price: BigNumber | null;
  merkleroot: string | null;
  merklerootPointer: BigNumber | null;
  counter: number | null;
  mintCount: number | null;
  presaleMintsRemaining: number | null;
  maxTokensInSale: number | null;
  maxMintPerAddress: number | null;
}
export enum SaleState {
  Off,
  Public1,
  Public2,
}

export const EthereumContext = createContext<Partial<IEthereumContextValues>>(
  {}
);

export function getErrorMessage(error: Error | any): string {
  log(`An error occurred:`);
  log(error);
  const fallbackError = {
    code: 4999,
    message: "An error occured. Check the console logs for more details (F12)",
  };
  error = serializeError(error, { fallbackError });
  console.log("shalom", error);
  if (error instanceof NoEthereumProviderError) {
    return "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.";
  } else if (error instanceof UnsupportedChainIdError) {
    return "You're connected to an unsupported network.";
  } else if (
    error instanceof UserRejectedRequestErrorInjected ||
    error instanceof UserRejectedRequestErrorWalletConnect ||
    error instanceof UserRejectedRequestErrorFrame
  ) {
    return "Please authorize this website to access your Ethereum account.";
  } else {
    let msg = "";
    const messageFromCode = getMessageFromCode(error.code, "Unknown error");
    log("getMessageFromCode:", messageFromCode);
    if (error.data && error.data.originalError) {
      log("Original error:");
      log(error.data.originalError);
    } else {
      return error.message;
    }
    switch (error.data.originalError.code) {
      case "INSUFFICIENT_FUNDS":
        msg = "The connected wallet has insufficient funds to cover that tx 😟";
        break;
      case -32002:
        msg =
          "Metamask: Permission request already pending. Open MetaMask to continue!";
        break;
      case 4001:
        msg = "Metamask: User denied transaction signature. ⛔";
        break;
      default:
        if (error.data.originalError.reason) {
          if (error.data.originalError.error) {
            msg = error.data.originalError.error.message;
          } else {
            msg = error.data.originalError.reason;
          }
        } else {
          msg = error.message;
        }
        log(`Error code ${error.code}: ${msg}`); //process other types
        break;
    }
    return msg;
  }
}

export const processError = (error: Error | any): void => {
  const msg = getErrorMessage(error);
  toast.error(`Error! ${msg}`);
};

export function getLibrary(
  provider:
    | ethers.providers.ExternalProvider
    | ethers.providers.JsonRpcFetchFunc
): providers.Web3Provider {
  const library = new providers.Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

export const EthereumProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [errorMsg, setErrorMsg] = useState<string | null>();
  const [isConnectedToMetamask, setIsConnected] = useState<boolean | undefined>(
    undefined
  );

  //activate the connection to our dedicated node
  const nodeContext = useWeb3React<providers.Web3Provider>("node");
  useEffect(() => {
    nodeContext.activate(network);
    // eslint-disable-next-line
  }, []);

  const { connector, library, account, activate, deactivate, active, error } =
    useWeb3React<providers.Web3Provider>("metamask");
  const [activatingConnector, setActivatingConnector] = useState<
    InjectedConnector | undefined
  >();
  const triedEager = useEagerConnect();

  //error listeners
  useEffect(() => {
    if (error) {
      //keep the errors in one toast and keep updating it
      const toastId = "single-error-message-toast";
      if (!toast.isActive(toastId)) {
        toast.error(getErrorMessage(error), {
          toastId,
        });
      } else {
        toast.update(toastId, {
          render: getErrorMessage(error),
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      }
    }
  }, [error]);

  useEffect(() => {
    if (error) {
      setErrorMsg(getErrorMessage(error));
    } else if (!active && !error) {
      setErrorMsg("Not connected to wallet");
    } else {
      setErrorMsg(null);
    }
  }, [active, error]);

  useInactiveListener(!triedEager || !!activatingConnector);

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  useEffect(() => {
    setIsConnected(!!(library && account));
  }, [library, account]);

  const connect = () => {
    // Metamask only for now
    setActivatingConnector(injected);
    activate(injected);
  };
  const logout = async () => {
    deactivate();
    injected.deactivate();
  };

  const contract = useMemo(() => {
    return new ethers.Contract(
      contractInformation.address,
      contractInformation.abi,
      library?.getSigner()
    );
  }, [library]);

  const contractNode = useMemo(() => {
    return new ethers.Contract(
      contractInformation.address,
      contractInformation.abi,
      nodeContext.library
    );
  }, [nodeContext.library]);

  //Track the state of the contract items we are interested in
  const [contractState, setContractState] = useState<IContractState>({
    owner: null,
    balanceOf: 0,
    maxSupply: null,
    maxMintPerTransaction: null,
    baseURI: null,
    tokensInWallet: [],
    totalSupply: null,
    saleIsCompleted: false,
    supplyRemaining: null,
    price: null,
    saleState: null,
    merkleroot: null,
    merklerootPointer: null,
    counter: null,
    presaleMintsRemaining: null,
    maxTokensInSale: null,
    maxMintPerAddress: null,
    mintCount: null,
  });

  //Set the state of a particular contract item
  function setContractStateKV(key: string, value: any) {
    setContractState((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  }

  //Function to get and update contract data state
  async function updateFixedState() {
    if (contractNode && nodeContext.library) {
      try {
        const owner: string = await contractNode.owner();
        const saleState: number = await contractNode.saleState();
        const { maxMintPerTransaction, price, maxTokensInSale } =
          await contractNode.saleData(saleState);
        const baseURI: string = await contractNode.baseURI();
        //const price: BigNumber = await contractNode.getPrice();

        setContractState((prevState) => {
          return {
            ...prevState,
            owner,
            maxMintPerTransaction,
            baseURI,
            price,
            saleState,
            maxTokensInSale,
          };
        });
        // console.log("Updated state");
      } catch (err: any) {
        console.log(`${err.message}`, err);
      }
    }
  }

  //Sync only the initial state of the fixed contract calls above
  useEffect(() => {
    updateFixedState();
  }, [contractNode && nodeContext.library]);

  //Function to get and update contract data state
  async function updateState() {
    if (contractNode && nodeContext.library) {
      try {
        const totalSupply: number = await contractNode.totalSupply();
        const saleState: number = await contractNode.saleState();
        const {
          maxMintPerTransaction,
          maxMintPerAddress,
          maxTokensInSale,
          counter,
          price,
        } = await contractNode.saleData(saleState);
        // const mintCount: number =
        //   await contractNode.getTokensMintedByAddressInSale(saleState, account);
        const supplyRemaining: number = constants.token.maxSupply - totalSupply;
        const saleIsCompleted: boolean =
          constants.token.maxSupply == totalSupply;
        setContractState((prevState) => {
          return {
            ...prevState,
            totalSupply,
            saleIsCompleted,
            supplyRemaining,
            maxMintPerTransaction,
            price,
            saleState,
            maxTokensInSale,
            counter,
            // mintCount,
          };
        });
        // console.log("Updated state");
        // console.log(contractState);
      } catch (err: any) {
        console.log(`${err.message}`, err);
      }
    }
  }

  //create contract object for new version of eth-hooks
  const contracts: Record<string, Contract> = {};
  contracts[constants.token.symbol] = contract;

  //Update the state on every block
  useOnRepetition(
    () => {
      updateState();
      updateBalanceOf();
    },
    { provider: nodeContext.library }
  );

  // useEffect(() => {
  // 	console.log(contractNode, nodeContext.library, contractState);
  // }, [contractNode, nodeContext.library, contractState]);

  // // On load update the state, as soon as the contract is ready
  useEffect(() => {
    updateState();
  }, [contractNode, nodeContext.library]);

  //seperate balanceOf calls since account value is not ready right away
  async function updateBalanceOf() {
    // const r = Math.random().toString().substring(2, 5);
    // debug && console.log(`updateBalanceOf`, r);
    if (contractNode && nodeContext.library && account) {
      try {
        //check if account has balance
        let balanceOf = 0;
        if (account) {
          balanceOf = (await contractNode.balanceOf(account)).toNumber();
          //   const saleState = await contractNode.saleState();
          //   const { maxMintPerAddress } = await contractNode.saleData(saleState);
          //   const MintCount: number = (
          //     await contractNode.getTokensMintedByAddressInSale(
          //       saleState,
          //       account
          //     )
          //   ).toNumber();
          //   setContractStateKV(
          //     "presaleMintsRemaining",
          //     maxMintPerAddress - MintCount
          //   );
        }

        setContractStateKV("balanceOf", balanceOf);
        let tokensInWallet: BigNumber[] = [];
        if (balanceOf && balanceOf > 0) {
          tokensInWallet = await contractNode.tokensInWallet(account);
        }
        setContractStateKV("tokensInWallet", tokensInWallet);
        // debug && console.log(`updateBalanceOf-DONE`, r);
      } catch (err: any) {
        console.log(`${err.message}`, err);
      }
    }
  }

  //make sure balanceOf updates when account finally loads
  useEffect(() => {
    updateBalanceOf();
  }, [contractNode, nodeContext.library, account]);

  const [merkleTree, setMerkleTree] = useState<MerkleTree | null>(null);
  const [isOnWhiteList, setIsOnWhiteList] = useState<boolean | null>(null);
  const [hexProof, setHexProof] = useState<string[] | null>(null);

  // get the root and hex proof for a whitelist
  useEffect(() => {
    const uniques_test = [...new Set(whitelistAddresses[1])];
    const checksumList_test = uniques_test.map((a: string) =>
      ethers.utils.getAddress(a)
    );
    const leafNodes_test = checksumList_test.map((a: string) => keccak256(a));
    const tree_test = new MerkleTree(leafNodes_test, keccak256, {
      sortPairs: true,
    });
    const root = tree_test.getHexRoot();
    console.log("get root", root);
    // console.log(
    //   "proof",
    //   tree.getHexProof(
    //     keccak256("0x5B38Da6a701c568545dCfcB03FcB875f56beddC4"),
    //     whiteList.indexOf("0x5B38Da6a701c568545dCfcB03FcB875f56beddC4")
    //   )
    // );
  }, []);

  useEffect(() => {
    if (account && contractState.saleState) {
      const uniques = [...new Set(whitelistAddresses[contractState.saleState])];
      const checksumList = uniques.map((a: string) =>
        ethers.utils.getAddress(a)
      );
      const leafNodes = checksumList.map((a: string) => keccak256(a));
      const tree = new MerkleTree(leafNodes, keccak256, {
        sortPairs: true,
      });
      setMerkleTree(tree);
      setIsOnWhiteList(
        checksumList.indexOf(ethers.utils.getAddress(account)) > -1
      );
      if (!tree) return;
      const hexProof = tree.getHexProof(keccak256(account));
      setHexProof(hexProof);
    } else {
      setIsOnWhiteList(null);
      setHexProof(null);
      setMerkleTree(null);
    }
  }, [account, contractState]);

  return (
    <EthereumContext.Provider
      value={{
        connect,
        logout,
        library,
        account,
        isConnectedToMetamask,
        errorMsg,
        nodeProvider: nodeContext.library,
        contract,
        contractNode,
        contractState,
        contracts,
        merkleTree,
        hexProof,
        isOnWhiteList,
      }}
    >
      {children}
    </EthereumContext.Provider>
  );
};
