import { createGlobalStyle } from "styled-components";
import { useTheme } from "../index";
import font1_woff from "../../fonts/MuseoSansRounded-100.woff";
import font1_woff2 from "../../fonts/MuseoSansRounded-100.woff2";
import font2_woff from "../../fonts/DIN-Black.woff";
import font2_woff2 from "../../fonts/DIN-Black.woff2";
import monument_bold from "../../fonts/PPMonumentExtended-Bold.woff";
import monument_bold2 from "../../fonts/PPMonumentExtended-Bold.woff2";
import monument_regular from "../../fonts/PPMonumentExtended-Regular.woff";
import monument_regular2 from "../../fonts/PPMonumentExtended-Regular.woff2";
import { constants } from "../constants";

export const GlobalStyleElem: any = createGlobalStyle`
  @font-face {
    font-family: "Monument Bold";
    src: url(${monument_bold2}) format('woff2'),
      url(${monument_bold});
  }

  @font-face {
    font-family: "Monument";
    src: url(${monument_regular2}) format('woff2'),
      url(${monument_regular});
  }
  @font-face {
    font-family: 'Jockey One';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/jockeyone/v13/HTxpL2g2KjCFj4x8WI6AnIHxHKGn8xY.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  
  @font-face {
    font-display: block;
    font-family:"Trendy";
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/392aa98f-a1bf-4dc4-9def-a5d27c73a0de.eot?#iefix");
    src:
      url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/392aa98f-a1bf-4dc4-9def-a5d27c73a0de.eot?#iefix") format("eot"),
      url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/29c66f1e-5243-4f34-8a19-47405f72954c.woff2") format("woff2"),
      url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/c81cca03-5f1a-4252-9950-096e60e2bde9.woff") format("woff"),
      url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/569b48ce-f71b-4e2d-a80a-1920efbd7187.ttf") format("truetype"),
      url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/f284409f-3669-43df-bfe1-f9f9ee1bbc07.svg#f284409f-3669-43df-bfe1-f9f9ee1bbc07") format("svg");
  }

  @font-face {
    font-display: block;
    font-family:"yeet";
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/0163ac22-50a7-406e-aa64-c62ee6fbf3d7.eot?#iefix");
    src:
      url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/0163ac22-50a7-406e-aa64-c62ee6fbf3d7.eot?#iefix") format("eot"),
      url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/aee74cb3-c913-4b54-9722-6001c92325f2.woff2") format("woff2"),
      url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/80de9d5d-ab5f-40ce-911b-104e51e93d7c.woff") format("woff"),
      url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/1b46b05b-cfdd-4d82-8c2f-5c6cfba1fe60.ttf") format("truetype"),
      url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/be340f0b-a2d4-41df-acb1-4dc124330a88.svg#be340f0b-a2d4-41df-acb1-4dc124330a88") format("svg");
  } 

  @font-face{
    font-family:"yeet2";
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Lulo_Clean/v1/LuloCleanW05-OneBold.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Lulo_Clean/v1/LuloCleanW05-OneBold.woff") format("woff");
  }

  @font-face {
    font-family:"nimbus-sans-extended";
    src:url("https://use.typekit.net/af/222328/000000000000000077359e1d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/222328/000000000000000077359e1d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/222328/000000000000000077359e1d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:400;
  }

  @font-face {
    font-family:"nimbus-sans-extended";
    src:url("https://use.typekit.net/af/d9b817/000000000000000077359e22/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/d9b817/000000000000000077359e22/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/d9b817/000000000000000077359e22/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:700;
  }

  @font-face {
    font-family: '${constants.fonts.body}';
    src:  url(${font1_woff2}) format('woff2'),
      url(${font1_woff}) format('woff');
  }
  @font-face {
    font-family: '${constants.fonts.title}';
    src:  url(${font2_woff2}) format('woff2'),
      url(${font2_woff}) format('woff');
  }
  body {
    font-family: '${constants.fonts.body}', sans-serif;
    margin: 0px;
    overflow-X: hidden;
    transition: all 4s ease 0;
    background-color: ${({ colorHex }: { colorHex: string }) =>
      colorHex ? colorHex : "#79b4e0"};
  }
  .text-center {
    text-align: center;
  }
  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }
  .navbar-link-active {
    border-bottom: 4px solid #ffe42e;
  }
  .icon-spin {
    -webkit-animation: icon-spin 2s infinite linear;
            animation: icon-spin 2s infinite linear;
  }
  @-webkit-keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }
  @keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }
`;

export const GlobalStyle: React.FunctionComponent = () => {
  const { theme, bgColor } = useTheme()!;

  return <GlobalStyleElem colorHex={bgColor} theme={theme} />;
};
