import React, { useState, useContext } from "react";
import {
  TopAlert,
  HolyGrail,
  HolyGrailBody,
  Navbar,
  Sidebar,
  Footer,
  AnimatedRoutes,
  RouteTransition,
  NoMatch404,
  EthereumContext,
  Connect,
  Mint,
  Inventory,
} from "./common";

const App: React.FunctionComponent = () => {
  const [sidebarVisible, toggleSidebar] = useState(false);
  const toggle = () => {
    toggleSidebar(!sidebarVisible);
  };
  const { isConnectedToMetamask } = useContext(EthereumContext);
  return (
    <React.Fragment>
      <Sidebar sidebarVisible={sidebarVisible} toggleSidebar={toggle} />
      <HolyGrail>
        <Navbar sidebarVisible={sidebarVisible} toggleSidebar={toggle} />
        <TopAlert />
        <HolyGrailBody>
          <AnimatedRoutes exitBeforeEnter initial={false}>
            <RouteTransition exact path="/" slide={35} slideUp={0}>
              {!isConnectedToMetamask ? <Connect /> : <Mint />}
            </RouteTransition>
            <RouteTransition exact path="/inventory" slide={35} slideUp={0}>
              {!isConnectedToMetamask ? <Connect /> : <Inventory />}
            </RouteTransition>
            <RouteTransition exact path={undefined} slide={35} slideUp={0}>
              <NoMatch404 />
            </RouteTransition>
          </AnimatedRoutes>
        </HolyGrailBody>
        <Footer />
      </HolyGrail>
    </React.Fragment>
  );
};

export default App;
