import styled from "styled-components";
import { motion } from "framer-motion";
import { NavLink as RLink } from "react-router-dom";
import bulova from "../../images/bulova.svg";
import dcave from "../../images/dcave.svg";
import {
  constants,
  Container,
  colors,
  topLogoAnim,
  useTheme,
  icons,
} from "../index";
import logo2 from "../../images/cupid_words-white.png";
import nav_bg from "../../images/nav-bg.png";

const Nav = styled.nav`
  position: relative;
  height: 100px;
  color: ${({ theme }: { theme: string }) =>
    theme === "dark" ? colors.text.nav.dark : colors.text.nav.light};
  display: flex;
  justify-content: center;
  transition: 0.4s ease all;
  flex-wrap: wrap;
  @media screen and (max-width: 1050px) {
    height: fit-content;
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  left: 0;
  // background-image: url(${nav_bg});
  background-color: ${() => colors.background.nav};
  // z-index: 0;
`;

const Link = styled.a`
  position: relative;
  padding-bottom: 10px;
  width: 100%;
  justify-content: center;
  margin: auto;
  animation: 2s ${topLogoAnim};
  // margin: 0;
  transition: 0.4s ease all;
  z-index: 2;
  height: 100%;
  // background: red;
  display: flex;
  flex-wrap: wrap;
  text-decoration: none;
  align-items: end;
  @media screen and (max-width: 1050px) {
    padding-top: 22px;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
  }
`;

const Bulova = styled.img`
  // padding: 10px;
  transition: 0.4s ease all;
  // width: 230px;
  height: 50%;
  // margin: auto;
  @media screen and (max-width: 1050px) {
    padding-top: 0px;
    width: 50%;
    margin: 5px 0px;
  }
`;
const Spacer = styled.div`
  width: 2%;
  margin: auto;
`;
const Dcave = styled.img`
  // padding: 10px;
  transition: 0.4s ease all;
  // width: 230px;
  height: 50%;
  // margin: auto;
  @media screen and (max-width: 1050px) {
    padding-top: 0px;
    width: 50%;
    margin: 5px 0px;
  }
`;

const NavTextLogoPlaceholder = styled.div`
  margin-right: auto;
`;

const NavMenu = styled.ul`
  margin: auto;
  list-style: none;
  white-space: nowrap;
  padding-right: 30px;
  @media screen and (max-width: 600px) {
    display: none;
  }
`;

const NavMenuItem = styled(motion.li)`
  padding: 0px 8px 0px 8px;
  display: inline-block;
  float: none;
  font-size: 0.9rem;
  @media screen and (max-width: 600px) {
    font-size: 1rem;
  }
`;

const MenuLink = styled(RLink)`
  text-decoration: none;
  color: black;
  display: flex;
  flex-direction: row;
  &:hover {
    color: #ffe42e;
    /* transform: translateY(-2px); */
  }
`;

const HamburgerIconContainer = styled.div`
  display: none;
  @media screen and (max-width: 600px) {
    display: block;
  }
  font-size: 16px;
  padding: 10px;
  cursor: pointer;
  &:hover {
    color: #ffe42e;
  }
`;

const NavbarIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 30px;
  align-items: center;
`;

const StyledIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  margin-bottom: 2px;
`;

const HamburgerIcon = ({ toggleSidebar }: { toggleSidebar: () => void }) => {
  const Icon = icons.GiHamburgerMenu;
  return (
    <HamburgerIconContainer onClick={toggleSidebar}>
      <Icon />
    </HamburgerIconContainer>
  );
};

interface INavbar {
  sidebarVisible: boolean;
  toggleSidebar: () => void;
}

export const Navbar = ({
  sidebarVisible,
  toggleSidebar,
}: INavbar): JSX.Element => {
  const { theme, gradient } = useTheme()!;
  return (
    <Nav theme={theme}>
      <Background />
      <Link href={constants.projectHomepage} title="Go to the homepage">
        <Dcave src={dcave} />
        <Spacer />
        <Bulova src={bulova} alt={`${constants.projectName} Logo`} />
        {/* <NavTitle>Real Face by Alana Tsui</NavTitle> */}
      </Link>
    </Nav>
  );
};
